<template>
  <div class="video-container">
    <section v-if="showHome" class="home">
      <div class="content">
        <img alt="Francesca" class="logo" src="../assets/logo-text-green.png">
      </div>
    </section>

    <!-- Video Section for Mobile (click-to-play) -->
    <section v-else-if="isMobile" class="video-section" @click="playVideo">
      <video ref="video" src="../assets/FK.mp4" poster="../assets/video-thumbnail.png" @ended="handleVideoEnd" playsinline>
        Your browser does not support the video tag.
      </video>
    </section>

    <!-- Video Section for Desktop (autoplay) -->
    <section v-else class="video-section">
      <video ref="video" src="../assets/FK.mp4" poster="../assets/video-thumbnail.png" @ended="handleVideoEnd" playsinline>
        Your browser does not support the video tag.
      </video>
    </section>
  </div>
</template>

<script>
export default {
  name: 'VideoView',
  data() {
    return {
      showHome: true, // Initially show the home view
      isMobile: false, // Flag to check if the device is mobile
      videoLoaded: false, // Flag to track whether the video source is loaded
    };
  },
  mounted() {
    // Check if the device is mobile based on the window size or user agent
    this.isMobile = window.innerWidth <= 768 || /Mobi|Android/i.test(navigator.userAgent);

    // Show the home screen for 3 seconds, then load the video lazily
    setTimeout(() => {
      this.showHome = false; // Hide home view and show video section
      this.loadVideo(); // Trigger video loading
    }, 3000);
  },
  methods: {
    // Trigger the video to play when the user clicks anywhere on the screen (for mobile)
    playVideo() {
      const videoElement = this.$refs.video;
      if (videoElement) {
        videoElement.play();
      }
    },
    handleVideoEnd() {
      this.showHome = true; // Show home view after video ends
    },
    loadVideo() {
      // Set the flag to load the video
      this.videoLoaded = true;
    },
  },
};
</script>


<style scoped>
.video-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.home {
  width: 100%;
  height: 100%;
  background-image: url('../assets/website-look-2/look2_3.jpeg');
  background-size: cover;
  background-position: center;
  backdrop-filter: blur(10px); /* Blur everything behind this element */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Ensure the home view stays above the video initially */
}

.video-section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0; /* Ensure the video is below the home view initially */
  }

video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire area */
  transform: translate(-50%, -50%);
}

.logo {
  display: block;
  width: 40%;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.5)); /* Adds a shadow around the logo */
}

.content {
  justify-content: space-around;
}
</style>
